import { useState } from 'react';
const apiUrl = import.meta.env.PUBLIC_API_BASE_URL;

export const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault(); // Prevents default form submission behavior

        const loginData = {
            username,
            password,
        };

        try {
            const response = await fetch(`${apiUrl}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            if (response.ok) {
                const authToken = await response.text();
                localStorage.setItem('cookie', authToken);

                const meResponse = await fetch(`${apiUrl}/me`, {
                    headers: {
                        'Authorization': authToken,
                    },
                });

                if (meResponse.ok) {
                    const userData = await meResponse.json();

                    // Role-based redirection
                    if (userData.rol === 1) {
                        window.location.href = 'dashboardAdmin';
                    } else if (userData.rol === 2) {
                        window.location.href = 'dashboard';
                    } else if (userData.rol === 4) {
                        window.location.href = 'dietesGestion';
                    } else if (userData.rol === 8) {
                        window.location.href = 'dashboard';    
                    } else if (userData.rol === 16) {
                        window.location.href = 'dietes';
                    } else {
                        setError('Rol no soportado');
                    }
                } else {
                    setError('Error al obtener datos del usuario');
                }
            } else {
                setError('Credenciales incorrectas');
            }
        } catch (error) {
            console.error("Error al hacer login:", error);
            setError('Error al conectar con el servidor');
        }
    };

    return (
        <form onSubmit={handleLogin}>
            <div className='z-10'>
                <label htmlFor="user" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Usuario</label>
                <input
                    name="user"
                    id="user"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="R99"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </div>
            <div>
                <label htmlFor="contraseña" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-4">Contraseña</label>
                <input
                    type="password"
                    name="contraseña"
                    id="contraseña"
                    placeholder="***********"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>

            {error && <div className="text-red-500">{error}</div>}

            <button
                type="submit"
                className="w-full px-5 py-3 text-base font-medium text-center text-white bg-custom-blue  hover:bg-custom-darkblue focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-sky-500 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-3"
            >
                Entrar
            </button>
        </form>
    );
};
